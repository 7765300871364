<template>
  <div>
    <div class="header">
      <b-col cols="2">
        <p class="text-header">{{ id > 0 ? "update" : "create" }} branch</p>
      </b-col>
      <div class="">
        <b-dropdown v-if="language" :text="language.name" class="m-md-2">
          <b-dropdown-item
            v-for="(item, index) in listLang"
            :key="index"
            @click="changeLanguage(item, index)"
          >
            <div :class="language.languageId === item.languageId && `item`">
              {{ item.name }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <div class="my-3">
        <InputText
          v-model="form.translationList[indexLanguage].name"
          class="input"
          :textFloat="`BranchName`"
          placeholder="BranchName"
          type="text"
          name="name"
          isRequired
          :v="$v.form.translationList.$each[indexLanguage].name"
          :isValidate="$v.form.translationList.$each[indexLanguage].name.$error"
          :selectLang="language"
        />
      </div>
      <div class="my-3">
        <InputTextArea
          v-model="form.translationList[indexLanguage].venueAddress"
          class="input"
          :textFloat="`Address`"
          placeholder="Address"
          type="text"
          rows="8"
          name="name"
          isRequired
          :v="$v.form.translationList.$each[indexLanguage].venueAddress"
          :isValidate="
            $v.form.translationList.$each[indexLanguage].venueAddress.$error
          "
          :selectLang="language"
        />
      </div>
      <b-row>
        <b-col>
          <div class="my-3">
            <InputSelect
              v-model="form.locationId"
              :options="listProvince"
              title="Province"
              class="mt-2"
              valueField="id"
              textField="name"
              isRequired
              :v="$v.form.locationId"
              :isValidate="$v.form.locationId.$error"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Select Province --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div>
        </b-col>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.locationUrl"
              class="input"
              :textFloat="`Google map url`"
              placeholder="Google map url"
              type="text"
              name="name"
              @onDataChange="onChangeLocationUrl"
              isRequired
              :v="$v.form.locationUrl"
              :isValidate="$v.form.locationUrl.$error"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.latitude"
              class="input"
              :textFloat="`latitude`"
              placeholder="latitude"
              type="number"
              name="name"
              isRequired
              :v="$v.form.latitude"
              :isValidate="$v.form.latitude.$error"
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.longitude"
              class="input"
              :textFloat="`longitude`"
              placeholder="longitude"
              type="number"
              name="name"
              isRequired
              :v="$v.form.longitude"
              :isValidate="$v.form.longitude.$error"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div v-if="!form.isLockRoom">
      <RoomForm
        :id="+$route.params.id || 0"
        :selected-lang="selectedLang"
        :index-language="indexLanguage"
      />
    </div>
    <div class="container-right mt-4">
      <b-button @click="handleSaveBranch" class="w-200 btn">{{
        this.id > 0 ? "Update" : "Create"
      }}</b-button>
    </div>
    <ModalAlertText
      :textModal="textModal"
      :arrModal="arrStatus"
      @clearArrModal="closeModal"
    />
  </div>
</template>

<script>
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
import RoomForm from "./roomForm.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "BranchForm",
  components: { AutocompleteCustom, RoomForm, ModalAlertText },
  data() {
    return {
      form: {
        locationId: null,
        translationList: [
          {
            id: 0,
            name: "",
            languageId: 2,
            venueAddress: "",
          },
          {
            id: 0,
            name: "",
            languageId: 1,
            venueAddress: "",
          },
        ],
      },

      selectedLang: 2,
      isLoading: false,
      listLang: [],
      listProvince: [],

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },
  validations() {
    return {
      form: {
        latitude: { required, minValue: minValue(1) },
        longitude: { required, minValue: minValue(1) },
        locationUrl: { required },
        locationId: { required },
        translationList: {
          $each: {
            name: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.translationList.find(
                  (x) => x.languageId === 2
                );
                return !requiredLanguage.name && this.selectedLang === 2;
              }),
            },
            venueAddress: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.translationList.find(
                  (x) => x.languageId === 2
                );
                return (
                  !requiredLanguage.venueAddress && this.selectedLang === 2
                );
              }),
            },
          },
        },
      },
    };
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  mounted() {},
  watch: {
    visible(val) {
      this.$v.$reset();
      this.isLoading = true;
      if (val === true) {
        this.getLanguageOption();
        this.getProvinceOption();

        this.getDefaultForm(this.id);
      } else {
      }
      this.isLoading = false;
    },
  },
  computed: {
    language() {
      return this.listLang.find((x) => x.languageId === this.selectedLang);
    },
    indexLanguage() {
      return this.form.translationList.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  methods: {
    async getDefaultForm(id) {
      const respone = await this.$services.venue.getVenueById(id);

      this.form = respone.detail;
      if (this.form.locationId === 0) this.form.locationId = null;
    },
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    async getProvinceOption() {
      const respone = await this.$services.master.getOptionProvince();
      this.listProvince = respone.detail.map((o) => {
        return { id: o.locationId, name: o.name };
      });
    },

    handleSaveBranch() {
      this.isLoading = true;
      if (this.form.locationId === 0) this.form.locationId = null;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$services.venue.saveVenue(this.form).then(async (res) => {
        const respone = await res;
        this.openModalAlertText(respone.message);
      });
      this.isLoading = false;
    },
    clearForm() {
      this.$emit("clearForm");
      this.isLoading = false;
    },

    onChangeLocationUrl(val) {},

    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
    closeModal() {
      this.$router.push(`/branch`);
    },
    changeLanguage(v, index) {
      this.selectedLang = v.languageId;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .item {
    color: var(--secondary-color);
  }
}
.btn {
  background-color: var(--secondary-color);
  color: white;
}

.container-right {
  display: flex;
  justify-content: right;
  align-items: center;

  .box-lang {
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid black;
    cursor: pointer;

    &.active {
      background: var(--secondary-color);
      color: white;
    }
  }
}
.text-header {
  color: #092d53;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}
</style>
