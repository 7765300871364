<template>
  <div>
    <div class="room-container">
      <div class="font-weight-bold mb-2 p-black">Room</div>
      <b-button @click="onClickOpenModalRoom(null)">Add</b-button>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="form"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template #cell(index)="{ item }">
        {{ item.id > 0 ? item.id : "New" }}
      </template>
      <template #cell(sortOrder)="{ item }">
        {{ item.sortOrder }}
      </template>
      <template #cell(name)="{ item }">
        {{ item.translationList[indexLanguage].name }}
      </template>
      <template #cell(locationCode)="{ item }">
        {{ item.locationCode }}
      </template>
      <template #cell(active)="{ item }">
        <b-form-checkbox
          v-model="item.isActive"
          class="pointer"
          switch
          :value="true"
          :unchecked-value="false"
        />
      </template>
      <template v-slot:cell(action)="{ item }">
        <div class="container">
          <font-awesome-icon
            class="mr-4 pointer"
            @click="onClickOpenModalRoom(item)"
            icon="pen"
          />

          <font-awesome-icon
            v-show="item.id <= 0"
            class="pointer"
            icon="trash-alt"
            @click="onClickDelete(item)"
          />
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template></b-table
    >
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
    <ModalAddRoom
      :visible="visibleModal"
      :room="formEdit"
      @closeModal="closeModal"
      @addRoom="addRoom"
    />
  </div>
</template>

<script>
import ModalAddRoom from "./modal/modalAddRoom.vue";
import Pagination from "@/components/Pagination";
export default {
  name: "RoomForm",
  components: { ModalAddRoom },
  props: {
    id: {
      type: Number,
      required: true,
    },
    selectedLang: {
      type: Number,
      required: true,
    },
    indexLanguage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,

      form: [],

      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "name",
          label: "name",
        },
        {
          key: "locationCode",
          label: "locationCode",
        },
        {
          key: "name",
          label: "name",
        },
        {
          key: "sortOrder",
          label: "sortOrder",
        },
        {
          key: "active",
          class: "w-50px text-nowrap",
        },
        {
          key: "action",
          class: "w-100px text-nowrap",
        },
      ],

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],

      filter: {
        skip: 1,
        take: 5,
        page: 1,
      },

      rows: 0,

      visibleModal: false,
      formEdit: {},
    };
  },
  mounted() {
    this.getRoomList();
  },
  methods: {
    async getRoomList() {
      if (this.id > 0) {
        const respone = await this.$services.venue.getRoomByVenueId(
          this.id,
          this.filter
        );
        if (respone.result === 1) {
          const { data, count } = respone.detail;
          this.rows = count;
          let formRespone = [];
          data.forEach((element, index) => {
            formRespone.push({ ...element, index: index });
          });
          this.form = formRespone;
        }
      }
    },
    onClickOpenModalRoom(val) {
      if (val !== null) this.formEdit = val;

      this.visibleModal = true;
    },
    addRoom(val) {
      if (val.id <= 0) {
        const newRoom = {
          ...val,
          id: 0,
          index:
            this.form.length === 0
              ? 0
              : this.form[this.form.length - 1].index + 1,
        };
        console.log(newRoom);
        this.form.push(newRoom);
      }
    },
    closeModal() {
      this.visibleModal = false;
      this.formEdit = {};
    },
    onClickDelete(val) {
      const index = this.form.findIndex((item) => {
        return item.index === val.index;
      });
      this.form.splice(index, 1);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.filterPage(this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.filterPage(this.filter);
    },
    filterPage(filter) {
      let { page } = filter;

      this.filter.skip = page;
      this.getRoomList();
    },
  },
};
</script>

<style scoped>
.room-container {
  display: flex;
  justify-content: space-between;
}
</style>
